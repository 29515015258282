body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1 0 auto;
}

main {
    margin: 0 auto;
    background-color: var(--gedc-white-gray);
    padding: 40px;
}

.float-reset {
    clear: both;
}

.header-wrapper {
    width: 1265px;
    max-width: calc(100% - 70px);
    margin: 0 auto;
}

/********* Form Stuff **********/

.footer-blue-container {
    padding: 50px 0 100px 0;
}

.footer-logo-text {
    margin-left: 20px;
}

.footer-icons {
    margin: 0 auto;
    padding-top: 50px;
    text-align: center;
    text-decoration: none;
}

.footer-icons__link {
    text-decoration: none;
}

.footer-icons__img {
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.footer-icons__img:hover {
    outline: 2px dashed var(--gedc-light-blue);
}

.footer-divider {
    margin: 50px 0;
    border: 1px solid #296fa9;
}

@media(max-width: 62em) {
    main {
        margin: 0 auto;
        min-width: 12.5rem;
        padding: 40px 20px;
    }

    .header-wrapper {
        max-width: calc(100% - 30px);
    }

    .navbar > .container-fluid {
        padding: 0;
    }

    .footer-blue-container {
        padding: 30px 15px 60px 15px;
    }

    .container--padding {
        padding: 0;
    }

    .footer-links {
        flex-direction: column;
        align-items: center;
    }
}

@media(max-width: 47em) {
    .gedc-button--drkBlue {
        width: 100%;
    }
}